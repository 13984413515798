


































































import {Component, Vue} from "vue-property-decorator";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import FileUpload from "@/components/common/FileUpload.vue";
import {namespace} from "vuex-class";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SalesTaxService from "@/services/SalesTaxService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import SalesTaxDocumentDTO from "@/dto/salestax/SalesTaxDocumentDTO";
import RouteNames from "@/router/RouteNames";
import UserDTO from "@/dto/UserDTO";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  components: {FileUpload}
})
export default class SalesTaxDocuments extends Vue {

  private message = "";

  private successful = true;

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Auth.State("user")
  private currentUser!: UserDTO;

  private years: Array<string> = [];

  private filter: { year: string | null } = {year: null};

  private documents: Array<SalesTaxDocumentDTO> = [];

  mounted() {
    this.loadYearsAndDocuments();
  }

  companyId() {
    return Number.parseInt(this.$route.params.companyId, 10);
  }

  onFilesUploaded(files: FileMetaDTO[]) {
    this.startLoading();
    SalesTaxService.saveSalesTaxDocumentList(Workspaces.getCurrent.id, files.map(f => f.id)).then(
        response => {
          this.loadYearsAndDocuments();
        },
        error => {
          this.stopLoading();
        }
    )
  }

  loadYearsAndDocuments() {
    this.loadYears().then(() => this.loadDocuments());
  }

  loadYears() {
    this.message = "";
    this.startLoading();
    return SalesTaxService.getYearsForCompany(Workspaces.getCurrent.id).then(
        response => {
          this.years = response.data;
          this.filter.year = response.data.sort().reverse()[0];
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    )
  }

  loadDocuments() {
    if (this.years.length == 0) {
      return Promise.resolve();
    }
    this.message = "";
    this.startLoading();
    return SalesTaxService.getSalesTaxDocuments(Workspaces.getCurrent.id, this.filter.year as string).then(
        response => {
          this.documents = response.data;
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    )
  }

  getPeriod(document: SalesTaxDocumentDTO) {
    switch (document.periodType) {
      case "MONTH":
        return this.getMonth(document);
      case "QUARTER":
        return this.getQuarter(document);
      case "SEASON":
        return this.getSeason(document);
      case "YEAR":
        return "Year";
      case "SEMESTER":
        return this.getSemester(document);
    }
  }

  getMonth(document: SalesTaxDocumentDTO) {
    switch (document.period.toUpperCase()) {
      case "M1":
      case "M01":
      case "1":
      case "01":
        return this.$t("period.month.jan");
      case "M2":
      case "M02":
      case "2":
      case "02":
        return this.$t("period.month.feb");
      case "M3":
      case "M03":
      case "3":
      case "03":
        return this.$t("period.month.mar");
      case "M4":
      case "M04":
      case "4":
      case "04":
        return this.$t("period.month.apr");
      case "M5":
      case "M05":
      case "5":
      case "05":
        return this.$t("period.month.may");
      case "M6":
      case "M06":
      case "6":
      case "06":
        return this.$t("period.month.jun");
      case "M7":
      case "M07":
      case "7":
      case "07":
        return this.$t("period.month.jul");
      case "M8":
      case "M08":
      case "8":
      case "08":
        return this.$t("period.month.aug");
      case "M9":
      case "M09":
      case "9":
      case "09":
        return this.$t("period.month.sep");
      case "M10":
      case "10":
        return this.$t("period.month.oct");
      case "M11":
      case "11":
        return this.$t("period.month.nov");
      case "M12":
      case "12":
        return this.$t("period.month.deс");
    }
  }

  getQuarter(document: SalesTaxDocumentDTO) {
    switch (document.period.toUpperCase()) {
      case "1":
      case "Q1":
        return this.$t("period.quarter") + " 1";
      case "2":
      case "Q2":
        return this.$t("period.quarter") + " 2";
      case "3":
      case "Q3":
        return this.$t("period.quarter") + " 3";
      case "4":
      case "Q4":
        return this.$t("period.quarter") + " 4";
    }
  }

  getSeason(document: SalesTaxDocumentDTO) {
    switch (document.period.toUpperCase()) {
      case "WINTER":
      case "1":
      case "S1":
        return this.$t("period.season.winter");
      case "SPRING":
      case "2":
      case "S2":
        return this.$t("period.season.spring");
      case "SUMMER":
      case "3":
      case "S3":
        return this.$t("period.season.summer");
      case "FALL":
      case "4":
      case "S4":
        return this.$t("period.season.fall");
    }
  }

  getSemester(document: SalesTaxDocumentDTO) {
    switch (document.period.toLowerCase()) {
      case "1":
        return this.$t("period.semester.first");
      case "2":
        return this.$t("period.semester.second");
    }
  }


}
